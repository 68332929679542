<template>
  <div class="private_container">
    <p>欢迎您使用云掌柜软件。请您务必仔细阅读并理解《用户服务协议》（以下简称“本协议”），本协议是您与北京米天下科技股份有限公司（以下简称“本公司”）就您使用云掌柜软件及服务所订立的协议。本协议对你和本公司均具有法律约束力。</p>
    <p>除非您已阅读并接受本服务协议所有条款，否则您无权下载、注册和使用本软件和相关服务。如您点“同意”、“注册”和“登录”等行为即视为您已阅读并同意本协议的约束。请你审慎阅读并选择是否接受本协议，如果你对本协议有任何疑问，应向客服咨询。</p>
    <h2>一、协议的范围</h2>
    <p>【签约主体】 视您所使用的具体服务而定，您可在相关服务的具体服务条款或说明文件中查看上述主体的名称和信息。“用户”是指使用云掌柜系统和服务的使用人，在本服务协议中更多称呼为“您”。</p>
    <p>【协议内容】本服务协议内容同时包括：</p>
    <p>(1)本协议内容同时包括《隐私政策》及所有云掌柜平台已经发布或未来可能发布的各类规则、公告或通知，一经发布即为本协议不可分割的组成部分，您同样应当遵守。如您不同意本协议的约定，您应立即停止注册并停止使用云掌柜的相关产品和服务。</p>
    <p>(2)第三方服务相关的用户授权协议。您可根据自身需要，通过云掌柜系统进行跳转等方式，自愿选择接受独立第三方服务商及部分关联第三方服务商所提供的服务，您将在使用该第三方服务前对相应的用户授权协议予以同意确认，以作为您和本公司之间就该等第三方服务的使用所涉及的相关事项的权利义务依据。</p>
    <p>(3)本公司有权更新用户协议、隐私权政策及相关平台规则（统称“用户协议”），届时协议更新会通过系统提示、信息推送通知和提醒您注意。 用户协议变更后，如您继使用本公司提供的服务，即表示您同意更新后的用户协议。如您不同意变更后的用户协议，请您停止使用产品和服务。 上述各项内容之间如存在不一致之处，以时间上最新发布的内容为准。</p>
    <h2>二、 注册</h2>
    <p>1、用户资格：您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。如果您不具备上述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。</p>
    <p>2、注册资料：用户应如实提交相关注册资料，不可提交任何违法或不良信息，相关资料如有变动，您应及时更新。如果因您所提供的信息不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将承担相应的法律责任及不利后果，并且本公司有权终止您使用相关产品和服务。</p>
    <p>3、信息保护：云掌柜将对您提交的注册信息予以保护，除非得到用户的授权或法律规定，本公司不会将其披露给任何非关联的第三方。</p>
    <h2>三、账号的使用</h2>
    <p>1、账号获得：当您按照注册页提示内容填写信息、阅读并同意本协议后，即可获得云掌柜账号。</p>
    <p>2、账号使用：您的账号仅限您本人使用，该账号下的所有行为（包括但不限于上传、发布、购买等行为）将视为您本人的真实意愿，并且您对其产生的一切后果负责。</p>
    <p>3、账户转让：由于用户账户关联用户信息，仅当有法律明文规定、司法裁定或符合我方平台规则规定的用户账户转让流程的情况下，您才可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则由此产生的一切责任均由您承担。</p>
    <h2>四、用户信息的合理使用</h2>
    <p>1、您知悉并认可：云掌柜平台向您提供的手机号、邮件、地址邮寄、站内信息或其他方式发送信息。</p>
    <p>2、您在使用我方产品或服务产生的数据信息，我方会按照《云掌柜隐私政策》收集、存储、使用、披露和保护您的个人信息。如果您不同意《隐私权政策》的任何内容，您应立即停止使用我方平台服务。</p>
    <p>3、本服务协议的成立、生效、履行、解释与纠纷解决，适用中华人民共和国大陆地区法律法规，并且排除一切冲突法规定的适用。</p>
    <p>4、本平台内的所有内容（用户依法享有版权的内容除外）、技术、软件、程序、数据及其他信息的所有知识产权及相关权利，均归我方或我方关联公司所有。未经我方许可，任何人不得擅自使用。</p>
  </div>
</template>
<script>
export default {
  name: "Agreement"
}
</script>

<style scoped lang="less">
.private_container{
  height: fit-content;
  background: white;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}
p{
  font-size: 14px;
  margin: 16px;
}
h2{
  margin: 16px;
}
</style>
